import { Action, State, StateContext } from '@ngxs/store';
import { PatchSorting, ResetSorting } from './sorting.actions';
import { SortingStateModel } from './sorting-state.model';
import { Injectable } from '@angular/core';

@State<Partial<SortingStateModel>>({
  name: 'sorting',
  defaults: {},
})
@Injectable()
export class SortingState {
  @Action(PatchSorting)
  public patch(ctx: StateContext<Partial<SortingStateModel>>, { sorting }: PatchSorting): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      ...sorting,
    });
  }

  @Action(ResetSorting)
  public reset(ctx: StateContext<Partial<SortingStateModel>>): void {
    ctx.setState({});
  }
}
