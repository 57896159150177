import { SortingStateModel } from '@store/mobile/sorting/sorting-state.model';

export class PatchSorting {
  public static readonly type = '[Sorting] Sorting patched';

  constructor(public sorting: Partial<SortingStateModel>) {}
}

export class ResetSorting {
  public static readonly type = '[Sorting] Reset Sorting';
}
